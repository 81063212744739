.update-project-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .back-button {
    transition: all 0.3s ease;
  }
  
  .back-button:hover {
    transform: translateX(-3px);
  }
  
  .image-upload-container {
    border: 2px dashed #ced4da;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .image-upload-container:hover {
    border-color: #0d6efd;
    background-color: rgba(13, 110, 253, 0.05);
  }
  
  .upload-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #6c757d;
    padding: 20px;
    text-align: center;
  }
  
  .upload-placeholder p {
    margin-top: 10px;
    margin-bottom: 0;
  }
  
  .image-preview-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
    border-radius: 4px;
  }
  
  .remove-image-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    opacity: 0.8;
    transition: all 0.2s ease;
  }
  
  .remove-image-btn:hover {
    opacity: 1;
    transform: scale(1.1);
  }
  
  /* Hide the default file input styling */
  input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  /* Card styling */
  .card {
    border: none;
    transition: box-shadow 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1) !important;
  }
  
  .card-header {
    border-bottom: none;
    padding: 1rem 1.5rem;
  }
  
  .card-body {
    padding: 1.5rem;
  }
  
  /* Form controls styling */
  .form-control {
    border-radius: 0.375rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .form-control:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
  
  textarea.form-control {
    min-height: 120px;
    resize: vertical;
  }
  
  /* Button styling */
  .btn {
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
  }
  
  .btn:hover {
    transform: translateY(-2px);
  }
  
  .btn:active {
    transform: translateY(0);
  }
  
  /* Spinner styling */
  .spinner-border {
    width: 1rem;
    height: 1rem;
    border-width: 0.15em;
  }
  
  /* Alert styling */
  .alert {
    border-radius: 0.375rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .row {
      flex-direction: column-reverse;
    }
    
    .image-upload-container {
      margin-bottom: 1.5rem;
    }
    
    .card-header {
      flex-direction: column;
      gap: 1rem;
    }
    
    .back-button {
      align-self: flex-start;
    }
  }