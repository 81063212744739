.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #343a40;
  color: white;
}

.admin-header .logo h1 {
  margin: 0;
  font-size: 1.5rem;
}

.admin-header nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.admin-header nav ul li {
  margin-left: 1.5rem;
}

.admin-header nav ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.2s;
}

.admin-header nav ul li a:hover {
  color: #17a2b8;
}

.logout-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.logout-btn:hover {
  background-color: #c82333;
}