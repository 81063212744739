.skills-container {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .card {
    border: none;
    transition: box-shadow 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1) !important;
  }
  
  .card-header {
    border-bottom: none;
    padding: 1rem 1.5rem;
  }
  
  .card-body {
    padding: 1.5rem;
  }
  
  .card-footer {
    background-color: #f8f9fa;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 0.75rem 1.5rem;
  }
  
  .refresh-btn, .add-btn, .back-btn, .submit-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
  }
  
  .refresh-btn:hover {
    transform: rotate(45deg);
  }
  
  .add-btn:hover, .submit-btn:hover {
    transform: translateY(-2px);
  }
  
  .back-btn:hover {
    transform: translateX(-3px);
  }
  
  .skills-table {
    margin-bottom: 0;
  }
  
  .skills-table th {
    border-top: none;
    border-bottom: 2px solid #dee2e6;
    font-weight: 600;
    color: #495057;
  }
  
  .skill-row {
    transition: background-color 0.2s ease;
  }
  
  .skill-row:hover {
    background-color: rgba(13, 110, 253, 0.05);
  }
  
  .id-badge {
    font-family: monospace;
    font-weight: 600;
    padding: 0.35em 0.65em;
  }
  
  .skill-name {
    font-weight: 500;
  }
  
  .icon-name {
    background-color: #f8f9fa;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    font-family: monospace;
    color: #d63384;
  }
  
  .delete-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
  }
  
  .delete-btn:hover {
    transform: translateY(-2px);
  }
  
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
  }
  
  /* Form styling */
  .form-control {
    border-radius: 0.375rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .form-control:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
  
  .form-label {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .form-text {
    font-size: 0.875rem;
  }
  
  /* Alert styling */
  .alert {
    border-radius: 0.375rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .card-header {
      flex-direction: column;
      gap: 1rem;
    }
    
    .card-header div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    
    .refresh-btn, .add-btn {
      width: 48%;
    }
    
    .skills-table th:first-child, 
    .skills-table td:first-child {
      padding-left: 0.5rem;
    }
    
    .skills-table th:last-child, 
    .skills-table td:last-child {
      padding-right: 0.5rem;
    }
    
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }