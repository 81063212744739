.img-thumbnail {
    max-width: 100px; /* Adjust the max-width as needed */
    max-height: 100px; /* Adjust the max-height as needed */
  }
  .project-container {
    padding: 2rem;
    background-color: #f8f9fa;
    min-height: calc(100vh - 60px);
  }
  
  .project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #dee2e6;
  }
  
  .project-header h2 {
    margin: 0;
    color: #343a40;
    font-weight: 600;
  }
  
  .project-actions {
    display: flex;
    gap: 10px;
  }
  
  .refresh-btn, .add-btn {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .project-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
  
  .project-image-container {
    height: 180px;
    overflow: hidden;
    position: relative;
    background-color: #e9ecef;
  }
  
  .project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .project-image:hover {
    transform: scale(1.05);
  }
  
  .no-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #6c757d;
    font-style: italic;
  }
  
  .project-description {
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 1rem;
    color: #495057;
  }
  
  .project-links {
    margin-bottom: 1rem;
  }
  
  .github-link {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: #212529;
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    transition: all 0.2s ease;
  }
  
  .github-link:hover {
    background-color: #e9ecef;
    color: #0d6efd;
  }
  
  .project-card-actions {
    display: flex;
    gap: 8px;
    margin-top: auto;
    padding-top: 1rem;
  }
  
  .edit-btn, .delete-btn, .view-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex: 1;
    padding: 0.375rem;
    font-size: 0.875rem;
  }
  
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    gap: 1rem;
  }
  
  .no-projects {
    text-align: center;
    padding: 3rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .no-projects p {
    color: #6c757d;
    font-size: 1.1rem;
  }
  
  /* Card footer styling */
  .card-footer {
    background-color: rgba(0, 0, 0, 0.03);
    padding: 0.75rem 1.25rem;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  /* Modal styling */
  .modal-content {
    border-radius: 0.5rem;
    border: none;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  
  .modal-header {
    border-bottom: 1px solid #dee2e6;
    background-color: #f8f9fa;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  
  .modal-footer {
    border-top: 1px solid #dee2e6;
    background-color: #f8f9fa;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .project-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
    
    .project-actions {
      width: 100%;
    }
    
    .refresh-btn, .add-btn {
      flex: 1;
      justify-content: center;
    }
    
    .project-grid {
      grid-template-columns: 1fr;
    }
  }
  
  /* Animation for cards */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .project-card {
    animation: fadeIn 0.5s ease-out forwards;
  }
  
  /* Staggered animation for multiple cards */
  .project-grid .project-card:nth-child(1) { animation-delay: 0.1s; }
  .project-grid .project-card:nth-child(2) { animation-delay: 0.2s; }
  .project-grid .project-card:nth-child(3) { animation-delay: 0.3s; }
  .project-grid .project-card:nth-child(4) { animation-delay: 0.4s; }
  .project-grid .project-card:nth-child(5) { animation-delay: 0.5s; }
  .project-grid .project-card:nth-child(6) { animation-delay: 0.6s; }
  
  /* Hover effects for buttons */
  .btn {
    transition: all 0.2s ease;
  }
  
  .btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .btn:active {
    transform: translateY(0);
  }
  
  /* Custom scrollbar for project description */
  .project-description::-webkit-scrollbar {
    width: 4px;
  }
  
  .project-description::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .project-description::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .project-description::-webkit-scrollbar-thumb:hover {
    background: #555;
  }