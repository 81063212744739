:root {
  --primary-color: #CD5FF8;
  --primary-dark: #b43ee0;
  --primary-light: #d980fa;
  --text-on-primary: #ffffff;
  --bg-light: #f8f9fa;
  --bg-dark: #1a1a2e;
}

.chatbot-container {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 50;
}

.chatbot-toggle {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 9999px;
  background-color: var(--primary-color);
  color: var(--text-on-primary);
  box-shadow: 0 10px 25px rgba(205, 95, 248, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}

.chatbot-toggle:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(205, 95, 248, 0.5);
}

.chatbot-toggle:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(205, 95, 248, 0.5), 0 10px 25px rgba(205, 95, 248, 0.4);
}
.chatbot-toggle {
  position: relative;
}

.chatbot-tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0;
  background-color: var(--primary-color);
  color: var(--text-on-primary);
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chatbot-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 20px;
  border-width: 8px;
  border-style: solid;
  border-color: var(--primary-color) transparent transparent transparent;
}

.chatbot-tooltip.show {
  opacity: 1;
  visibility: visible;
}
.chatbot-dialog {
  position: absolute;
  bottom: 4.5rem;
  right: 0;
  width: 24rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(205, 95, 248, 0.2);
  transition: all 0.3s ease-in-out;
  animation: slideUp 0.3s ease-out forwards;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chatbot-header {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  color: var(--text-on-primary);
  padding: 1rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.chatbot-header h3 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 0;
}

.chatbot-header button {
  color: var(--text-on-primary);
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s;
}

.chatbot-header button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.chatbot-messages {
  flex: 1;
  padding: 1.25rem;
  overflow-y: auto;
  max-height: 24rem;
  background-color: var(--bg-light);
  scroll-behavior: smooth;
}

.message {
  margin-bottom: 1rem;
  animation: fadeIn 0.3s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.user-message {
  display: flex;
  justify-content: flex-end;
}

.assistant-message {
  display: flex;
  justify-content: flex-start;
}

.message-content {
  max-width: 80%;
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  line-height: 1.5;
  position: relative;
}

.user-message .message-content {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  color: var(--text-on-primary);
  border-bottom-right-radius: 0.25rem;
}

.assistant-message .message-content {
  background-color: white;
  color: #333;
  border-bottom-left-radius: 0.25rem;
  border: 1px solid rgba(205, 95, 248, 0.2);
}

.typing-indicator {
  display: flex;
  gap: 0.375rem;
  padding: 0.25rem 0;
  align-items: center;
}

.typing-indicator span {
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--primary-color);
  border-radius: 9999px;
  opacity: 0.7;
  animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
  animation-delay: -0.32s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.chatbot-input {
  border-top: 1px solid rgba(205, 95, 248, 0.1);
  padding: 1rem;
  background-color: white;
}

.input-container {
  display: flex;
  align-items: center;
  background-color: var(--bg-light);
  border-radius: 9999px;
  padding: 0.25rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
}

.input-container:focus-within {
  box-shadow: 0 0 0 2px rgba(205, 95, 248, 0.3);
}

.chatbot-input input {
  flex: 1;
  border: none;
  background: transparent;
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  outline: none;
  color: #333;
}

.chatbot-input input::placeholder {
  color: #9ca3af;
}

.chatbot-input button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.send-button-enabled {
  background-color: var(--primary-color);
  color: var(--text-on-primary);
}

.send-button-enabled:hover {
  background-color: var(--primary-dark);
  transform: translateY(-1px);
}

.send-button-disabled {
  background-color: #e5e7eb;
  color: #9ca3af;
  cursor: not-allowed;
}

@media (max-width: 640px) {
  .chatbot-dialog {
    width: calc(100vw - 2rem);
    max-width: 24rem;
    right: -0.5rem;
  }
  
  .chatbot-messages {
    max-height: 60vh;
  }
}

/* Scrollbar styling */
.chatbot-messages::-webkit-scrollbar {
  width: 6px;
}

.chatbot-messages::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.chatbot-messages::-webkit-scrollbar-thumb {
  background: rgba(205, 95, 248, 0.3);
  border-radius: 10px;
}

.chatbot-messages::-webkit-scrollbar-thumb:hover {
  background: rgba(205, 95, 248, 0.5);
}
/* Add these styles to your existing Chatbot.css file */

.chatbot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbot-header-buttons {
  display: flex;
  align-items: center;
}

.clear-history-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  padding: 5px;
  color: #888;
  transition: color 0.3s;
}

.clear-history-button:hover {
  color: #ff6b6b;
}

.clear-history-button svg {
  width: 16px;
  height: 16px;
}